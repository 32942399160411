<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card" style="padding: 24px;">
        <div class="organization-title">
          {{ $route.meta.title }}
        </div>
        <div class="organization-body">
          <div style="top: -20px;display: flex;">
            <div style="width: 280px;" class="left-organization">
              <div class="left-organization-title">组织架构</div>
              <!-- 新版接口组织管理 -->
              <el-tree :data="organizationalData"
                       ref="organizationalTree"
                       default-expand-all
                       node-key="node_id"
                       highlight-current
                       class="org-tree tree"
                       :indent="0"
                       :props="{ children: 'distributorList', label: 'name'}"
                       :expand-on-click-node="false"
                       @node-click="nodeClick">
                <template #default="{node, data}">
                  <el-row class="custom-node-item"
                          :class="{'distributor-item' : data.nodeType === 'DEALER'}">
                    <el-col :span="22" style="padding-right: 16px;">
                      <div class="line-overflow default-label" style="font-size: 14px;">{{ node.label }}</div>
                    </el-col>
                    <el-col :span="2" style="display: flex;justify-content: flex-end;padding-right: 8px;">
                      <div class="dropdown-container">
                        <el-dropdown v-show="commandShow(
                                  checkPermission('zz:add') && data.nodeType === 'DEVELOPER',
                                  checkPermission('zz:edit') && data.nodeType === 'DEVELOPER',
                                  checkPermission('zz:addjxs') && (data.nodeType === 'DEVELOPER'),
                                  checkPermission('zz:editjxs') && data.nodeType === 'DEALER'
                                  )" trigger="hover" @command="commandHandler(data, $event)">
                          <!--<el-dropdown v-show="true" trigger="hover" @command="commandHandler(data, $event)">-->
                          <span class="el-dropdown-link">
                            <custom-show-icon custom-image custom-image-resource="menu"></custom-show-icon>
                          </span>
                          <el-dropdown-menu slot="dropdown">
                            <div>
                              <!--isDevelopers是开发商可以新增开发商和经销商-->
                              <!-- 新增开发商 -->
                              <el-dropdown-item command="addDeveloper">
                                <el-button v-if="checkPermission('zz:add')&&data.nodeType==='DEVELOPER'&&isDevelopers"
                                           type="text" size="mini">新增开发商
                                </el-button>
                              </el-dropdown-item>
                              <!-- 编辑开发商 -->
                              <el-dropdown-item command="treeEditActionV2">
                                <el-button v-if="checkPermission('zz:edit')&&data.nodeType==='DEVELOPER'&&isDevelopers"
                                           type="text" size="mini">编辑
                                </el-button>
                              </el-dropdown-item>
                              <!-- 新增经销商 -->
                              <el-dropdown-item command="treeAppendActionV2">
                                <el-button
                                    v-if="checkPermission('zz:addjxs')&&data.nodeType==='DEVELOPER'&&isDevelopers"
                                    type="text" size="mini">新增经销商
                                </el-button>
                              </el-dropdown-item>
                              <!-- 编辑经销商 -->
                              <el-dropdown-item command="treeEditActionV2">
                                <el-button v-if="checkPermission('zz:editjxs') && data.nodeType === 'DEALER'"
                                           type="text" size="mini">编辑
                                </el-button>
                              </el-dropdown-item>
                            </div>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </el-col>
                  </el-row>
                </template>
              </el-tree>
            </div>
            <div style="flex: 1;overflow-x: auto;">
              <page-table ref="table"
                          :show-title="true"
                          title-content="人员账号"
                          show-paginate
                          :get-data="userPage"
                          :fields="fields"
                          :search-model="searchModel"
                          :item-actions="actions"
                          show-card-left-border
                          @onActionClick="handleItemActionClick">
                <template slot="search-items">
                  <el-form-item label="角色" prop="webSearchRoleId">
                    <el-select v-model="searchModel.webSearchRoleId" placeholder="角色">
                      <el-option :label="type.name" :value="type.id" v-for="type in roleOptions" :key="type.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="账号状态" prop="webSearchAuthOver" style="width: 210px;flex: unset;">
                    <el-select style="width: 100%;" v-model="searchModel.webSearchAuthOver" placeholder="账号状态">
                      <el-option :label="type.label" :value="type.value" v-for="type in userState" :key="type.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="员工信息" prop="webSearch" style="width: 210px;flex: unset;">
                    <el-input v-model="searchModel.webSearch" placeholder="姓名 手机号"></el-input>
                  </el-form-item>
                </template>
                <template slot="page-actions">
                  <div style="margin-bottom: 18px;">
                    <el-button icon="el-icon-plus" v-permission="'zz:add_user'" @click="handleAdd" size="small"
                               type="primary">新增员工
                    </el-button>
                  </div>
                </template>
              </page-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--提醒实名弹框-->
    <el-dialog title="提醒实名链接" :visible.sync="auth_dialog_visible" width="500px"
               :close-on-click-modal="false"
               :close-on-press-escape="false" :modal-append-to-body="false">
      <div>
        <div style="font-size: 18px;font-weight: bold;" id="auth-link">{{ auth_link }}</div>
        <div style="margin-top: 20px;font-size: 12px;">温馨提示：5分钟有效，只能使用一次</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="copyLink">复制链接</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {
  userPage,
  realNameAuth,
  roleAll, userDelete
} from "@/api/common"
import {APP_NAME, USER_STATE} from "@/utils/config"
import {getDistributor, psnEmpowerAuth} from "@/api/apis";
import '@/styles/LPopup.scss'
import CustomShowIcon from "@/components/CustomShowIcon.vue";
import checkPermission from "@/utils/checkPermission";

export default {
  name: "Authorities",
  data() {
    return {
      searchModel: {
        webSearch: '',
        webSearchRoleId: '', // 角色
        webSearchAuthOver: '', // 账号状态
      },
      userPage: userPage,
      fields: [
        {label: 'UserId', key: 'id', width: 180},
        // {label: '账号', key: 'username', width: 120},
        {label: '姓名', key: 'name', width: 100,},
        {
          label: '性别', key: 'gender', width: 60,
          render({gender}, h) {
            return h('div', gender == 0 ? '男' : (gender == 1 ? '女' : ''))
          }
        },
        {label: '手机号', key: 'phone', width: 120},
        {
          label: '用户类型', key: 'userType', width: 100,
          render({userType}, h) {
            let types = {
              admin: '集团用户',
              enterprise: '企业用户',
              distributor: '经销商',
            }
            if (types[userType] !== undefined) {
              return h('div', types[userType])
            }
            return h('div')
          }
        },
        {
          label: '归属组织', width: 120,
          render: ({developers, distributor, userType}, h) => {
            if (!userType) {
              return h("span", '')
            } else if (userType == 'admin') {
              return h("span", APP_NAME)
            } else if (userType == 'enterprise') {
              return h("span", developers.name)
            } else if (userType == 'distributor') {
              return h("span", distributor.name)
            }
          }
        },
        {
          label: '角色权限', width: 120,
          render({roleList}, h) {
            let tmp = [];
            if (roleList) {
              for (let roleListElement of roleList) {
                tmp.push(roleListElement.name)
              }
            }
            return h('span', tmp.join('，'));
          }
        },
        {
          label: '账号状态', width: 80,
          render: ({isSalesman, isAuthOver}, h) => {
            if (isSalesman) {
              if (isAuthOver) {
                return (<el-tag type="success" color="#E8F8E9" style="color: #19B21E;">已实名</el-tag>)
              } else {
                return (<el-tag type="warning" color="#FFF5E6" style="color: #FA9600;">未实名</el-tag>)
              }
            } else {
              return h('span', '')
            }
          }
        },
        {label: '授权到期时间', key: 'eExpireTime', align: 'center', width: 180},
        {label: '创建时间', key: 'time', align: 'center', width: 180},
      ],
      actions: [
        {
          action: 'auth', label: '提醒实名', type: 'primary',
          showAction: item => {
            return (item.isSalesman && !item.isAuthOver)
          }
        },
        {
          action: 'edit',
          label: '编辑',
          type: 'primary',
          permission: 'zz:edit_user',
          showAction: admin => {
            return !admin.is_supper_admin
          }
        },
        {
          action: 'del',
          label: '删除',
          type: 'primary',
          permission: 'zz:del_user',
        },
        {
          action: 'accredit', label: '授权', type: 'primary',
          showAction: item => {
            return item.epsnId
          }
        },
      ],
      // 经销商
      auth_dialog_visible: false,
      auth_link: null,
      roleOptions: [],
      userState: USER_STATE,
      // 组织管理
      organizationalData: [],
      isDevelopers: false,
    }
  },
  components: {
    CustomShowIcon,
    PageHeader,
    PageTable
  },
  created() {
    this.getRoles()
    // 获取组织
    this.getDistributorV2()
  },
  mounted() {

  },
  methods: {
    checkPermission,
    commandHandler(data, fun) {
      this[fun](data)
    },
    commandShow(...conditions) {
      for (let condition of conditions) {
        if (condition) {
          return true
        }
      }
      return false
    },
    /**
     * 组织管理
     * 2024-05-16改： 1，不添加根节点COMPANY_NAME；2去掉（开发商）和（经销商）标记文字
     */
    getDistributorV2() {
      getDistributor().then(res => {
        const {isDevelopers} = res
        this.isDevelopers = isDevelopers

        res.data.map(iter => {
          iter.node_id = `DEVELOPER_${iter.id}`
          iter.nodeType = 'DEVELOPER'
          if (iter.distributorList && iter.distributorList.length > 0) {
            iter.distributorList.map(item => {
              item.node_id = `DEALER_${item.id}`
              item.nodeType = 'DEALER'
            })
          }
        })
        this.organizationalData = res.data
        // console.log('组织架构数据', this.organizationalData)
        // 选择默认节点
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.organizationalTree.setCurrentKey(res.data[0].node_id)
            // 查询该结点的数据
            this.nodeClick(res.data[0])
          }, 100)
          // -
          this.refreshLine()
        })
      })
    },
    /**
     * 新增开发商 | 经销商 新
     * @param data
     */
    treeAppendActionV2(data) {
      console.log(data.id)
      this.$router.push('/admin/edit-dealer')
    },
    addDeveloper(){
      this.$router.push('/admin/edit-developer')
    },
    /**
     * 编辑开发商|经销商
     * @param data
     */
    treeEditActionV2(data) {
      if (data.nodeType === 'DEVELOPER') {
        this.$router.push(`/admin/edit-developer/${data.id}`)
      } else if (data.nodeType === 'DEALER') {
        this.$router.push(`/admin/edit-dealer/${data.id}`)
      }
    },
    getRoles() {
      let that = this;
      roleAll().then(res => {
        that.roleOptions = res;
      }).catch(() => {

      })
    },
    // 复制链接
    copyLink() {
      let that = this
      const cInput = document.createElement('input')
      cInput.value = that.auth_link
      document.body.appendChild(cInput)
      cInput.select()
      document.execCommand('Copy')
      that.$message.success('复制成功')
      that.auth_dialog_visible = false
      cInput.remove()
    },
    nodeClick(node) {
      // ROOT DEALER-经销商 DEVELOPER-开发商
      switch (node.nodeType) {
        case 'DEALER':
          this.searchModel.distributorId = node.id
          this.searchModel.developersId = ''
          break
        case 'DEVELOPER':
          this.searchModel.developersId = node.id
          this.searchModel.distributorId = ''
          break
        default:
          this.searchModel.distributorId = ''
          this.searchModel.developersId = ''
      }
      this.$refs.table.loadData()
      this.refreshLine()
    },
    refreshLine() {
      this.$nextTick(() => {
        const distributorArr = [...document.querySelectorAll('.distributor-item')]
        distributorArr.forEach(iter => {
          if (iter !== null) {
            const parentNode = iter.parentNode;
            if (parentNode !== null) {
              const parentNode1 = parentNode.parentNode;
              if (parentNode1 !== null) {
                parentNode1.classList.add('node-line-container')
              }
            }
          }
        })
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    handleAdd() {
      this.$router.push({
        path: '/admin/add-staff',
        query: {'developersId': this.searchModel.developersId, 'distributorId': this.searchModel.distributorId}
      })
    },
    // e签宝
    accreditAction(item) {
      let that = this
      psnEmpowerAuth(item.id).then(res => {
        that.auth_link = res
        that.auth_dialog_visible = true
      })
    },
    // 提醒实名
    authAction(item) {
      let that = this
      if (!item.isAuthUser) {
        realNameAuth(item.id).then(res => {
          that.auth_link = res
          that.auth_dialog_visible = true
        })
      }
    },
    delAction(item) {
      let that = this
      that.$confirm('确认删除用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        userDelete(item.id).then(() => {
          that.$message.success('操作成功');
          that.$refs.table.loadData()
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    /**
     * 编辑员工
     * @param item
     */
    editAction(item) {
      this.$router.push(`/admin/edit-staff/${item.id}`)
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .dashboard-container-table {
  padding: 5px 20px 20px;
}

/deep/ .el-icon-caret-right:before {
  color: #73787E;
  font-size: larger;
}

/deep/ .is-leaf:before {
  color: #18191A00;
}

.organization-title {
  padding-bottom: 25px;
  font-size: 20px;
  color: #18191A;
  font-weight: bold;
  border-bottom: 1px solid #EEF1F5;
}

.organization-body {

}

.left-organization {
  margin-top: 5px;
  padding: 20px 0 0;
  background-color: #fff;

  .tree /deep/ .el-tree-node {
    position: relative;
    padding-left: 0;
  }

  .tree /deep/ .el-tree-node__children {
    padding-left: 16px;
  }

  .tree /deep/ .el-tree-node :last-child:before {
    height: 12px;
  }

  .tree /deep/ .el-tree > .el-tree-node:before {
    border-left: none;
  }

  .tree-container /deep/ .el-tree > .el-tree-node:after {
    border-top: none;
  }

  .tree /deep/ .node-line-container:before {
    content: '';
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
    border-left: 1px solid #E6E6E6;
    bottom: 0px;
    height: 100%;
    top: 5px;
    width: 1px;
  }

  .tree /deep/ .node-line-container:after {
    content: '';
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
    border-top: 1px solid #E6E6E6;
    height: 20px;
    top: 17px;
    width: 25px;
  }

  .left-organization-title {
    margin-bottom: 20px;
    font-size: 16px;
    color: #18191A;
    font-weight: bold;
  }

  .dropdown-container {

    .el-icon-more-outline {
      transform: rotateZ(90deg);
      color: #0D6CE4;
    }
  }

}

.line-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.default-label {
  color: #18191A;
}

.org-tree {
  /deep/ .org-tree-node {
    width: 100%;
    flex: 1;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
}

/deep/ .el-tree--highlight-current .el-tree-node .el-tree-node__content {
  height: 32px;
}

/deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #E7F1FD;
  border-radius: 4px;

  .default-label {
    color: #0D6CE4 !important;
  }
}

.custom-node-item {
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 100%;
  height: 100%;
}
</style>
